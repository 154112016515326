@use 'functions' as *;

// Colors
// https://marvelapp.com/prototype/fb8gdcb/screen/95158720
$primaryColor: #2c2b2a;
$primaryDarkColor: #689154;
$primaryUltraDarkColor: #547544;
$primaryLightColor: #b9d7aa;

$secondaryColor: #85b96b;
$secondaryDarkColor: #689154;
$secondaryUltraDarkColor: #547544;
$secondaryLightColor: #b9d7aa;

$gray-100: #424649;
$gray-200: #686766;
$gray-300: #979696;
$gray-400: #c7c6c6;
$gray-500: #e8e8e8;
$websiteBlack: #2c2b2a;
$black: #000000;
$brown: #ce5e53;
$pink: #eb88a2;
$lightblue: #099fdf;
$purple: #c08bcc;
$green: #bad974;
$success: #85b96b;
$danger: #ff4949;
$error: #ff4949;
$warning: #fd9f00;
$lightBackground: #f5f5f5;
$white: #ffffff;
$sonicSilver: #797979;
$mutedGray: #a2a5b9;
$iguanaGreen: #72be8c;
$brightGray: #eaeaea;
$platinum: #e8e8e8;
$cultured: #f4f4f4;
$spanishGray: #979696;
$mutedSilver: #c7c6c6;
$softMist: #e6e5e5;
$charcoalGray: #686766;
$ebony: #3a3938;
$blueGray: #888da8;
$pixelWhite: #dbdbdb;
$gainsboro: #e3e3e3;
$arsenic: #393a4f;
$robinEggBlue: #039be5;
$spanishGray: #999999;
$jet: #363636;
$kinglyCloud: #dedede;
$nobleBlack: #202023;
$cornYellow: #f5f574;
$pistachio: #8dc580;
$stormySlate: #757a91;
$gold: #ffd700;
$paleSilver: #d8d8d8;
$linkedin: #0e76a8;
$facebook: #3b5998;
$stripe: #635bff;

$ambassadorGold: #c59947;
$ambassadorSilver: #99aeb4;

$toolbarDividerColor: rgba($sonicSilver, 0.16);

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

// Font Families
$fontFamilyRoboto: 'Roboto', sans-serif;
$fontFamilyMontserrat: 'Montserrat', sans-serif;

$fontAwesomeClasses: (
    '.fa-solid',
    '.fa-regular',
    '.fa-brands',
    '.fas',
    '.far',
    '.fab',
    '.fal',
    '.fat',
    '.fad',
    '.fass',
    '.fasr',
    '.fasl',
    '.fast',
    '.fasds',
    '.fa-light',
    '.fa-thin',
    '.fa-duotone',
    '.fa-sharp',
    '.fa-sharp-duotone',
    '.fa-sharp-solid',
    '.fa-classic',
    '.fa'
);

// Overrides

$fontFamily: $fontFamilyRoboto;
$fontSize: to-rem(16);
$textColor: $websiteBlack;
$textSecondaryColor: $gray-200;
$borderRadius: to-rem(6);

// This overrides the default text color for most of components
$shade700: $textColor;

// This overrides the default text color for the following components, they were previously set to $shade800
$inputListHeaderTextColor: $textColor;
$panelHeaderTextColor: $textColor;
$tableHeaderCellTextColor: $gray-100;
$tableHeaderCellPadding: to-rem(14) to-rem(14);
$tableHeaderCellBg: $lightBackground;
$tableFooterCellTextColor: $textColor;
$tableFooterTextColor: $textColor;
$tableBodyCellPadding: to-rem(14) to-rem(14);
$dialogHeaderTextColor: $textColor;
$submenuHeaderTextColor: $textColor;

// Button
$buttonPadding: to-rem(11) to-rem(20);
$buttonIconOnlyWidth: to-rem(40);
$buttonIconOnlyPadding: to-rem(10);
$buttonTextColor: #fff;
$buttonTextFontSize: to-rem(15);
$buttonTextLineHeight: to-rem(18);
$buttonTextFontWeight: $font-weight-medium;
$buttonBorder: none;
$outlinedButtonBorder: to-rem(1) solid $primaryColor;
$buttonHoverBg: $gray-100;
$buttonTextHoverColor: #fff;
$buttonHoverBorderColor: $primaryColor;
$buttonActiveBg: $gray-100;
$buttonTextActiveColor: #fff;
$buttonActiveBorderColor: $primaryColor;
$buttonBorderRadius: to-rem(20);
$buttonPrimaryDisabledBg: $gray-400;

// Disabled button
$buttonPrimaryDisabledTextColor: $gray-200;
$buttonPrimaryDisabledBg: $gray-400;
$buttonPrimaryDisabledBorder: $gray-400;

// Secondary button
$secondaryButtonBg: $secondaryColor;
$secondaryButtonBorder: to-rem(1) solid $secondaryColor;
$secondaryButtonHoverBg: $secondaryDarkColor;
$secondaryButtonHoverBorderColor: $secondaryDarkColor;
$secondaryButtonActiveBg: $secondaryUltraDarkColor;
$secondaryButtonActiveBorderColor: $secondaryUltraDarkColor;

$successButtonBg: $secondaryColor;
$successButtonHoverBg: $secondaryDarkColor;

// Link button
$linkButtonColor: $primaryColor;
$linkButtonHoverColor: $gray-100;
$linkButtonTextHoverDecoration: underline;
$linkButtonFocusShadow: none;
$buttonLinkBorderRadius: 0;
$buttonTextBorderRadius: 0;

// Checkbox
$checkboxWidth: to-rem(22px);
$checkboxHeight: to-rem(22px);
$checkboxIconFontSize: to-rem(14);
$checkboxBorder: to-rem(2) solid $secondaryColor;
$checkboxActiveBorderColor: $secondaryColor;
$checkboxActiveBg: $secondaryColor;
$checkboxIconActiveColor: #fff;
$checkboxActiveHoverBg: $secondaryDarkColor;
$checkboxIconActiveHoverColor: #fff;
$checkboxActiveHoverBorderColor: $secondaryDarkColor;

// TabView
$tabviewHeaderPadding: to-rem(11);
$tabviewHeaderTextColor: $gray-200;
$tabviewHeaderFontSize: to-rem(14);
$tabviewHeaderFontWeight: $font-weight-bold;
$tabviewHeaderTextHoverColor: $secondaryColor;
$tabviewHeaderHoverBorderColor: $secondaryColor;
$tabviewHeaderActiveBorderColor: $secondaryColor;
$tabviewHeaderTextActiveColor: $secondaryColor;

$raisedButtonShadow:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
$roundedButtonBorderRadius: 2rem;

// Radio button
$radiobuttonWidth: to-rem(22);
$radiobuttonHeight: to-rem(22);
$radiobuttonIconSize: to-rem(12);
$radiobuttonActiveBorderColor: $secondaryColor;
$radiobuttonActiveBg: $secondaryColor;
$radiobuttonActiveHoverBg: $secondaryDarkColor;
$radiobuttonActiveHoverBorderColor: $secondaryDarkColor;

// Input Switch
$inputSwitchWidth: to-rem(42);
$inputSwitchHeight: to-rem(24);
$inputSwitchBorderRadius: to-rem(12);
$inputSwitchHandleWidth: to-rem(18);
$inputSwitchHandleHeight: to-rem(18);
$inputSwitchHandleBorderRadius: 50%;
$inputSwitchSliderPadding: to-rem(3);
$inputSwitchSliderOnBg: $secondaryColor;
$inputSwitchSliderOnHoverBg: $secondaryDarkColor;

// Input (text)
$inputPadding: to-rem(10) to-rem(12) to-rem(8) to-rem(12);
$inputBorder: to-rem(1) solid $gray-300;
$inputHoverBorderColor: $secondaryColor;
$inputFocusBorderColor: $secondaryColor;
$inputPlaceholderTextColor: $gray-300;

// Invalid input
$focusInvalidOutlineColor: $danger;
$focusInvalidShadow: 0 0 0 0.2rem $focusInvalidOutlineColor !default;

// Rating
$ratingColor: $secondaryColor;

//Card
$cardBorderRadius: to-rem(12);
$cardShadow: none;
$cardContentPadding: 0;
$cardBodyPadding: to-rem(20);

// Tooltip
$tooltipTextColor: $white;
$tooltipFontSize: to-rem(12);
$tooltipLineHeight: to-rem(16);
$tooltipBg: $gray-100;
$tooltipBorderRadius: to-rem(4);

// Topbar
$topbarHeight: to-rem(80);
$topbarZIndex: 1016;
$promoTopBarHeight: to-rem(74);

// Editorial sidebar
$sidebarWidth: to-rem(80);

// Chat sidebar
$chatSidebarWidth: to-rem(60);
$chatTopbarHeight: to-rem(60);

// Bootstrap
$body-color: $textColor;

// Home
$home-container-max-width: to-rem(1581);

:root {
    --topbar-height: #{$topbarHeight};
    --promo-topbar-height: #{$promoTopBarHeight};
    --topbar-z-index: #{$topbarZIndex};
    --bs-body-font-family: #{$fontFamily};
    --bs-body-color: #{$textColor};
    --bs-black: #{$websiteBlack};
    --feed-page-vertical-padding: #{to-rem(32)};
    --sidebar-width: #{$sidebarWidth};
    --chat-sidebar-width: #{$chatSidebarWidth};
    --chat-topbar-height: #{$chatTopbarHeight};
    --actions-height: #{to-rem(60)};
    --filter-topbar-visible-height: #{to-rem(80)};

    font-family: $fontFamily;
}

$mobile-max-width: 932px; // Remember to update also the value in responsive-width.ts

$mobile-page-card-padding: to-rem(24) to-rem(8) to-rem(24);
