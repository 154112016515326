.menu {
    &:before,
    &:after {
        content: none !important;
    }

    .p-overlaypanel-content {
        padding: 0;
    }

    .user-info {
        margin: 0;
        padding: 1.25rem 1rem;
    }

    .divider {
        margin: 0;
    }

    .actions {
        margin: 0;
        padding: 1rem 0;

        button {
            width: 100%;
            border-radius: 0;

            padding: 0.75rem 1.25rem;
        }
    }
}
