@import 'global';

.p-button.p-button-outlined:disabled {
    color: $gray-200;
    border: to-rem(1) solid $gray-200;
    opacity: 1;
}

.p-button:disabled {
    color: $buttonPrimaryDisabledTextColor;
    border: $buttonPrimaryDisabledBorder;
    opacity: 1;

    &:not(.p-button-text) {
        background-color: $buttonPrimaryDisabledBg;
    }
}

.round-action-button.round-action-button.round-action-button {
    height: 1.75rem;
    width: 1.75rem;

    &.p-button-text {
        color: $shade500;
    }
}

.p-button {
    padding: to-rem(11) to-rem(20);
    border-radius: $buttonBorderRadius;

    justify-content: center;

    .p-button-label {
        font-size: $buttonTextFontSize;
        line-height: $buttonTextLineHeight;
        vertical-align: middle;
        font-weight: $buttonTextFontWeight;
        flex: unset;
    }

    .p-button-icon {
        font-size: to-rem(20);
        line-height: $buttonTextLineHeight;
        vertical-align: middle;
    }

    &.p-button-icon {
        padding: to-rem(10);
    }
}

button.p-button-outlined {
    padding: to-rem(10) to-rem(19);
}

.p-button-link {
    padding: to-rem(8) to-rem(20);
    border-radius: $buttonLinkBorderRadius;
}

.p-button-text {
    color: $textColor;
    border-radius: $buttonTextBorderRadius;
}

.p-splitbutton-defaultbutton {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.p-splitbutton-menubutton {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    line-height: $buttonTextLineHeight;
}

a.p-button {
    text-decoration: none;
    color: $buttonTextColor;
    font-size: $buttonTextFontSize;
    line-height: $buttonTextLineHeight;
    font-weight: $buttonTextFontWeight;
}

.p-button.p-button-secondary.p-button-outlined {
    border-color: $secondaryColor;
}

.p-button.p-button-danger.p-button-outlined {
    border-color: $danger;
}

.p-button.p-button-warning.p-button-outlined {
    border-color: $warning;
}

.p-button.p-button-success,
.p-button.p-button-success.p-button-outlined {
    border-color: $success;
}
