@use 'functions' as *;
@use 'mixins' as *;

@import 'variables';

// NOTE: this import is from node_modules, not from the libs/ui/ui-shared-style/styles/_bootstrap.scss.
// In the future, we will use the custom Boostrap file in order to include only the necessary components.
@import 'bootstrap' layer(bootstrap);
@import 'primeng/resources/primeng.min.css';
@import 'themes/lara/lara-light/green/theme';
@import 'components';
@import 'typography';
@import 'utils';
@import 'responsive';
@import 'primeicons';

html {
    height: 100%;
    font-size: var(--html-font-size, 81.25%); // if tou change this value, update the image-font-size.directive.ts file
}

@include mobile() {
    html {
        font-size: 100%;
    }
}

body {
    height: 100%;
}

:root {
    --fa-primary-color: #{$primaryColor};
}
