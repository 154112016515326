$primaryColor: #10b981 !default;
$primaryLightColor: #a7f3d0 !default;
$primaryDarkColor: #059669 !default;
$primaryDarkerColor: #047857 !default;
$primaryTextColor: #ffffff !default;

$highlightBg: #f0fdfa !default;
$highlightTextColor: $primaryDarkerColor !default;
$highlightFocusBg: rgba($primaryColor, 0.24) !default;

@import '../_variables';
@import './_fonts/fonts';
@import '../../../../designer/_components';
@import '../_extensions';
