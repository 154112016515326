// Configuration for the font-face of the theme, defaults to the system font so left as blank

/** =================== MONTSERRAT =================== **/

/** Montserrat Thin **/
@font-face {
    font-family: 'Montserrat';
    font-weight: 100;
    font-style: normal;
    src:
        url('Montserrat-Thin.woff2') format('woff2'),
        url('./Montserrat-Thin.woff') format('woff');
}

/** Montserrat Thin-Italic **/
@font-face {
    font-family: 'Montserrat';
    font-weight: 100;
    font-style: italic;
    src:
        url('Montserrat-ThinItalic.woff2') format('woff2'),
        url('Montserrat-ThinItalic.woff') format('woff');
}

/** Montserrat ExtraLight **/
@font-face {
    font-family: 'Montserrat';
    font-weight: 200;
    font-style: normal;
    src:
        url('Montserrat-ExtraLight.woff2') format('woff2'),
        url('Montserrat-ExtraLight.woff') format('woff');
}

/** Montserrat ExtraLight-Italic **/
@font-face {
    font-family: 'Montserrat';
    font-weight: 200;
    font-style: italic;
    src:
        url('Montserrat-ExtraLightItalic.woff2') format('woff2'),
        url('Montserrat-ExtraLightItalic.woff') format('woff');
}

/** Montserrat Light **/
@font-face {
    font-family: 'Montserrat';
    font-weight: 300;
    font-style: normal;
    src:
        url('Montserrat-Light.woff2') format('woff2'),
        url('Montserrat-Light.woff') format('woff');
}

/** Montserrat Light-Italic **/
@font-face {
    font-family: 'Montserrat';
    font-weight: 300;
    font-style: italic;
    src:
        url('Montserrat-LightItalic.woff2') format('woff2'),
        url('Montserrat-LightItalic.woff') format('woff');
}

/** Montserrat Regular **/
@font-face {
    font-family: 'Montserrat';
    font-weight: 400;
    font-style: normal;
    src:
        url('Montserrat-Regular.woff2') format('woff2'),
        url('Montserrat-Regular.woff') format('woff');
}

/** Montserrat Regular-Italic **/
@font-face {
    font-family: 'Montserrat';
    font-weight: 400;
    font-style: italic;
    src:
        url('Montserrat-Italic.woff2') format('woff2'),
        url('Montserrat-Italic.woff') format('woff');
}

/** Montserrat Medium **/
@font-face {
    font-family: 'Montserrat';
    font-weight: 500;
    font-style: normal;
    src:
        url('Montserrat-Medium.woff2') format('woff2'),
        url('Montserrat-Medium.woff') format('woff');
}

/** Montserrat Medium-Italic **/
@font-face {
    font-family: 'Montserrat';
    font-weight: 500;
    font-style: italic;
    src:
        url('Montserrat-MediumItalic.woff2') format('woff2'),
        url('Montserrat-MediumItalic.woff') format('woff');
}

/** Montserrat SemiBold **/
@font-face {
    font-family: 'Montserrat';
    font-weight: 600;
    font-style: normal;
    src:
        url('Montserrat-SemiBold.woff2') format('woff2'),
        url('Montserrat-SemiBold.woff') format('woff');
}

/** Montserrat SemiBold-Italic **/
@font-face {
    font-family: 'Montserrat';
    font-weight: 600;
    font-style: italic;
    src:
        url('Montserrat-SemiBoldItalic.woff2') format('woff2'),
        url('Montserrat-SemiBoldItalic.woff') format('woff');
}

/** Montserrat Bold **/
@font-face {
    font-family: 'Montserrat';
    font-weight: 700;
    font-style: normal;
    src:
        url('Montserrat-Bold.woff2') format('woff2'),
        url('Montserrat-Bold.woff') format('woff');
}

/** Montserrat Bold-Italic **/
@font-face {
    font-family: 'Montserrat';
    font-weight: 700;
    font-style: italic;
    src:
        url('Montserrat-BoldItalic.woff2') format('woff2'),
        url('Montserrat-BoldItalic.woff') format('woff');
}

/** Montserrat ExtraBold **/
@font-face {
    font-family: 'Montserrat';
    font-weight: 800;
    font-style: normal;
    src:
        url('Montserrat-ExtraBold.woff2') format('woff2'),
        url('Montserrat-ExtraBold.woff') format('woff');
}

/** Montserrat ExtraBold-Italic **/
@font-face {
    font-family: 'Montserrat';
    font-weight: 800;
    font-style: italic;
    src:
        url('Montserrat-ExtraBoldItalic.woff2') format('woff2'),
        url('Montserrat-ExtraBoldItalic.woff') format('woff');
}

/** Montserrat Black **/
@font-face {
    font-family: 'Montserrat';
    font-weight: 900;
    font-style: normal;
    src:
        url('Montserrat-Black.woff2') format('woff2'),
        url('Montserrat-Black.woff') format('woff');
}

/** Montserrat Black-Italic **/
@font-face {
    font-family: 'Montserrat';
    font-weight: 900;
    font-style: italic;
    src:
        url('Montserrat-BlackItalic.woff2') format('woff2'),
        url('Montserrat-BlackItalic.woff') format('woff');
}
