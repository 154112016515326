.p-tabview-nav-link {
    font-size: $tabviewHeaderFontSize;

    &:hover {
        color: $tabviewHeaderTextHoverColor;
    }
}

.p-highlight .p-tabview-nav-link {
    color: $tabviewHeaderTextActiveColor;
}

.p-tabview-title {
    font-size: to-rem(14);
    line-height: to-rem(17);
}

.tabview-full-width {
    .p-tabview-nav {
        display: flex;
    }

    .p-tabview-nav li {
        flex: 1;
    }

    .p-tabview-title {
        margin: auto;
    }
}
