@use 'functions' as *;

a {
    color: $primaryColor;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $fontFamilyMontserrat;
    font-weight: 600;
}

h1 {
    font-size: to-rem(36);
    line-height: to-rem(44);
}

h2 {
    font-size: to-rem(28);
    line-height: to-rem(34);
}

h3 {
    font-size: to-rem(22);
    line-height: to-rem(27);
}

h4 {
    font-size: to-rem(16);
    line-height: to-rem(19);
}

.title-normal {
    font-family: $fontFamilyRoboto;
    font-size: to-rem(16);
    line-height: to-rem(19);
    font-weight: 500;
}

.title-small {
    color: $websiteBlack;
    font-family: $fontFamilyRoboto;
    font-size: to-rem(14);
    line-height: to-rem(19);
    font-weight: 500;
}

.body-large {
    color: $websiteBlack;
    font-family: $fontFamilyRoboto;
    font-size: to-rem(20);
    line-height: to-rem(30);
}

.body-normal {
    color: $websiteBlack;
    font-family: $fontFamilyRoboto;
    font-size: to-rem(16);
    line-height: to-rem(22);
    font-weight: 400;
}

.body-small {
    color: $websiteBlack;
    font-family: $fontFamilyRoboto;
    font-size: to-rem(14);
    line-height: to-rem(19);
    font-weight: 400;
}

.body-tiny {
    color: $websiteBlack;
    font-family: $fontFamilyRoboto;
    font-size: to-rem(12);
    line-height: to-rem(16);
    font-weight: 400;
}

.button-small {
    color: $websiteBlack;
    font-family: $fontFamilyRoboto;
    font-size: to-rem(13);
    line-height: to-rem(16);
    font-weight: 500;
}
