p-card {
    &.p-page-card-responsive {
        display: block;
        margin-top: to-rem(15);

        .p-card-body {
            padding: to-rem(46) to-rem(32) to-rem(71);
            @include mobile() {
                padding: $mobile-page-card-padding;
            }
        }

        .title {
            margin-bottom: to-rem(32);
        }
    }
}
