.input-label {
    color: $textColor;
    font-weight: bold;
    margin-bottom: to-rem(8);
}

.p-checkbox-box {
    border-radius: to-rem(6);
}

.p-inputgroup-addon {
    height: to-rem(36);
    + .p-inputtext {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.p-inputtext {
    height: to-rem(40);
    font-size: to-rem(16);
    line-height: to-rem(22);
    transition: all 0.3s;

    &.p-inputtextarea {
        height: auto;
    }

    &.ng-dirty.ng-invalid:enabled:focus {
        box-shadow: $focusInvalidShadow;
    }
}

.control {
    label {
        font-size: to-rem(14.4);
        font-weight: 500;
        color: $gray-100;
    }
}
