.p-editor.ng-invalid:not(.ng-pristine) {
    .p-editor-toolbar {
        border-left: to-rem(1) solid $error;
        border-right: to-rem(1) solid $error;
        border-top: to-rem(1) solid $error;
    }

    .p-editor-content {
        border-left: to-rem(1) solid $error;
        border-right: to-rem(1) solid $error;
        border-bottom: to-rem(1) solid $error;
    }
}
