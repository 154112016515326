@use 'functions' as *;
@use 'mixins' as *;

.p-dialog {
    border-radius: to-rem(8);

    .p-dialog-header {
        padding: to-rem(24) to-rem(24);
    }

    .p-dialog-title {
        font-family: $fontFamilyMontserrat;
        font-size: to-rem(16);
        color: $textColor;
        font-weight: 600;
    }

    .p-dialog-content {
        font-size: to-rem(15);
    }

    .p-dialog-footer {
        padding-bottom: to-rem(28);
    }

    &.p-confirm-dialog .p-dialog-footer .p-button-secondary {
        border-color: unset;
        color: unset;
    }
}

.no-header {
    .p-dialog-content {
        border-radius: to-rem(8);
    }
}

.no-x-padding {
    .p-dialog-content {
        padding: 0 0 to-rem(32) 0;
    }

    &.p-confirm-dialog .p-confirm-dialog-message {
        margin-left: to-rem(24);
    }
}

.no-y-padding {
    .p-dialog-content {
        padding-bottom: 0;
        padding-top: 0;
    }
}

@include mobile() {
    .modal-mobile-full-screen {
        height: 100vh !important;
        max-height: 100vh;
        border-radius: 0;
    }
}
