@use 'mixins' as *;

@include mobile {
    .p-toast-bottom-right {
        left: 0;
        right: unset;
        bottom: 0;
        width: 100dvw;
        height: auto;
    }
}
