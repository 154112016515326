@use 'variables' as *;

// Potrait and Landscape
@mixin mobile() {
    // add 1 to media query to make sure it's fired on the exact last supported width
    @media (max-width: $mobile-max-width + 1) {
        @content;
    }
}

// Potrait
@mixin mobile-portrait() {
    // add 1 to media query to make sure it's fired on the exact last supported width
    @media (max-width: $mobile-max-width + 1) and (max-aspect-ratio: 3/4) {
        @content;
    }
}

// Landscape
@mixin mobile-landscape() {
    // add 1 to media query to make sure it's fired on the exact last supported width
    @media (max-width: $mobile-max-width + 1) and (min-aspect-ratio: 4/3) {
        @content;
    }
}

@mixin desktop() {
    // add 1 to media query to make sure it's fired on the exact last supported width
    @media (min-width: $mobile-max-width + 1) {
        @content;
    }
}
