@use '../functions' as *;

.has-slimscroll {
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: to-rem(6);
        height: to-rem(6);
    }

    &::-webkit-scrollbar-thumb {
        border-radius: to-rem(10);
        background: rgba($websiteBlack, 0.2);
    }
}

.has-slimscroll-horizontal {
    overflow-y: hidden;

    &::-webkit-scrollbar {
        height: to-rem(6);
    }

    &::-webkit-scrollbar-thumb {
        border-radius: to-rem(10);
        background: rgba($websiteBlack, 0.2);
    }
}
