@use 'mixins' as *;

.d-mobile-none {
    @include mobile() {
        display: none !important;
    }
}

.d-mobile-portrait-none {
    @include mobile-portrait() {
        display: none !important;
    }
}

.d-mobile-landscape-none {
    @include mobile-landscape() {
        display: none !important;
    }
}

.d-desktop-none {
    @include desktop() {
        display: none !important;
    }
}

.with-fixed-topbar {
    padding-top: var(--topbar-height);
}

.ms-auto-desktop {
    @include desktop() {
        margin-left: auto;
    }
    @include mobile() {
        margin-left: 0;
    }
}
