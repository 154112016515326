p-password {
    width: 100%;

    .p-inputtext {
        width: 100%;
    }

    .p-password-input {
        width: 100%;
    }

    .p-password {
        width: 100%;
    }

    .p-password-panel {
        width: 100%;
    }

    &.ng-invalid.ng-dirty {
        .p-inputtext {
            box-shadow: $focusInvalidShadow;
        }
    }
}
